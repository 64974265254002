// for npm roboto-fontface package (to load local files)
$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface";

// for npm material-icons package (to load local files)
$material-icons-font-path: '~material-icons/iconfont/';
@import '../node_modules/material-icons/iconfont/material-icons.scss';


@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import "app/shared/theme/styles/base";
@import "app/shared/theme/styles/spaces";
@import "app/shared/theme/styles/theme";
@import "app/shared/theme/styles/libs-override";
@import "app/shared/theme/styles/rtl";









.c-tab .mat-ink-bar {
    height: 5px;
}

.app.indigo-light .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #e7e8ee;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
    opacity: 1 !important;
}

.no-padding-mat-form-field {
    margin-bottom: -1.25em !important;
}

.no-border-radius {
    border-radius: 0px !important;
}

.app-confirm-dialog .mat-mdc-dialog-container {
    padding: 0px !important;
}

.app-delete-timecard-dialog .mat-mdc-dialog-container {
    padding: 0px !important;
}